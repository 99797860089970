<template>
  <div id="secondary-insurance">
    <div class="secondary-insurance-view">
      <div class="step-inner-navbar"></div>

      <div class="step-inner-content">
        <div class="vue-form">
          <el-form v-model="secondaryInsuranceForm">
            <el-row :gutter="15">
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">
                      Relationship
                      <span class="star">*</span>
                    </label>
                    <el-select
                      v-model="secondaryInsuranceForm.relationship"
                      placeholder="Select Relationship"
                      @change="fillPrimaryDetails()"
                      @clear="clearNameSearch"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      clearable
                      
                    >
                      <el-option
                        v-for="(type, index) in relationships"
                        :key="index"
                        :value="type"
                        :label="type"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.relationship"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">
                      Plan Type
                      <span class="star">*</span>
                    </label>
                    <el-select
                      placeholder="Select Plan Type"
                      v-model="secondaryInsuranceForm.plan_type"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      
                    >
                      <el-option
                        v-for="(type, index) of planTypes"
                        :key="index"
                        :value="type"
                        :label="type"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.plan_type"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">
                      First Name
                      <span class="star">*</span>
                    </label>
                    <el-input
                      v-model="secondaryInsuranceForm.first_name"
                      placeholder="Enter First Name"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.first_name"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text"> Middle Name </label>
                    <el-input
                      v-model="secondaryInsuranceForm.middle_name"
                      placeholder="Enter Middle Name"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.middle_name"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">
                      Last Name
                      <span class="star">*</span>
                    </label>
                    <el-input
                      v-model="secondaryInsuranceForm.last_name"
                      placeholder="Enter Last Name"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.last_name"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="2" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input gender-group">
                  <el-form-item>
                    <label for="text"> Gender </label>
                    <el-select
                      v-model="secondaryInsuranceForm.gender"
                      placeholder="Select Gender"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      
                      clearable
                    >
                      <el-option
                        v-for="(gender, index) in genderOptions"
                        :key="index"
                        :label="gender"
                        :value="gender"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.gender"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="date-of-birth case-creation__input">
                  <dobVersion
                    v-if="showPatientDob"
                    :dobData="secondaryInsuranceForm"
                    :validataionErrors="getErrors"
                    :errorKey="'billing_info.insurance.secondary_insurance.date_of_birth'"
                    :displayRequired="false"
                  >
                  </dobVersion>
                </div>
              </el-col>

              <el-col :xl="4" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <payor
                      :payorData="secondaryInsuranceForm"
                      v-if="show"
                      :clearPayor="clearPayorData"
                      insuranceType="secondary_insurance"
                      @payorSelect="fillInsurance"
                    >
                    </payor>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.payor"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text"> Insurance Carrier Code </label>
                    <el-input
                      v-model="secondaryInsuranceForm.insurance_carrier_code"
                      placeholder="Enter Insurance Carrier Code"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">
                      Policy No
                      <span class="star">*</span>
                    </label>
                    <el-input
                      v-model="secondaryInsuranceForm.policy_id"
                      placeholder="Enter Policy No"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.policy_id"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text">Group #</label>
                    <el-input
                      v-model="secondaryInsuranceForm.group"
                      placeholder="Enter Group #"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.group"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text"> Address Line 1 </label>
                    <el-input
                      v-model="secondaryInsuranceForm.address_line_1"
                      placeholder="Enter Address Line 1"
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.address_line_1"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text"> Address Line 2 </label>
                    <el-input
                      v-model="secondaryInsuranceForm.address_line_2"
                      placeholder="Enter Address Line 2"
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.address_line_2"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="2" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <label for="text"> Zip </label>
                    <el-input
                      v-model="secondaryInsuranceForm.zip"
                      placeholder="Enter Zip"
                      maxlength="5"
                      @input="getStateCity"
                      
                    ></el-input>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.zip"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <state
                      :stateData="secondaryInsuranceForm"
                      :displayRequired="false"
                    ></state>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.state"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="6" :sm="8" :xs="12">
                <div class="case-creation__input">
                  <el-form-item>
                    <city
                      :cityData="secondaryInsuranceForm"
                      v-if="show"
                      :displayRequired="false"
                    ></city>
                    <p class="err">
                      <FormError
                        errorName="billing_info.insurance.secondary_insurance.city"
                      ></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import caseConstants from "@/config/constants/caseConstants";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
const payor = defineAsyncComponent(() => import("@/components/misc/Payor"));
const dobVersion = defineAsyncComponent(() =>
  import("@/components/misc/DobNewFormat")
);
const state = defineAsyncComponent(() =>
  import("@/components/misc/BillingState")
);
const city = defineAsyncComponent(() =>
  import("@/components/misc/BillingCity")
);
import AppHelper from "@/mixins/AppHelper";
import InsuranceHelper from "@/mixins/InsuranceHelper";

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      secondaryInsuranceForm: JSON.parse(
        JSON.stringify(caseConstants.insurance)
      ),
      relationships: caseConstants.BILLING_RELATIONSHIPS,
      planTypes: ["INDIVIDUAL", "FAMILY"],
      genderOptions: ["MALE", "FEMALE", "OTHERS"],
      payorData: {},
      show: false,
      showPatientDob: false,
      clearPayorData: false,
      patientData: {},
      facilityState: "",

      stateCarrierMapping: caseConstants.stateCarrierMapping,
      stateMedicaidCarrierMapping: caseConstants.stateMedicaidCarrierMapping,
      stateAetnaMapping: caseConstants.stateAetnaMapping,
    };
  },
  components: {
    FormError,
    payor,
    dobVersion,
    state,
    city,
  },
  props: [
    "parentRefs",
    "secondaryInsurance",
    "refreshDob",
    "patientInfo",
    "state",
  ],

  mixins: [AppHelper, InsuranceHelper],
  computed: {
    ...mapGetters("help", ["getAllStates"]),
    ...mapGetters("cases", ["getCaseInformation", "getStateAndCity"]),
    ...mapGetters("errors", ["getErrors"]),
  },
  mounted() {
    // this.fetchStates();
    this.show = true;
    this.showPatientDob = true;
    if (this.secondaryInsurance) {
      this.secondaryInsuranceForm = { ...this.secondaryInsurance };
    }
    if (this.$route.params.case_id) {
      this.checkCaseInfo();
      this.patientData = this.getCaseInformation.patient_info;
    }
    this.facilityState = this.state;
  },
  methods: {
    async getStateCity() {
      try {
        let params = {
          zip: this.secondaryInsuranceForm.zip,
        };
        await this.$store.dispatch("cases/getStateCity", params);
        if (this.getStateAndCity) {
          this.secondaryInsuranceForm.state = this.getStateAndCity.state;
          this.secondaryInsuranceForm.city = this.getStateAndCity.city;
        } else {
          if (!this.getStateAndCity && params.zip.length == 5) {
            this.$message({
              message: "No State & City Found",
              type: "warning",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchStates() {
      await this.$store.dispatch("help/fetchAllStates");
    },

    getSecondaryInsuranceFormData() {
      return this.getFormattedValues(this.secondaryInsuranceForm);
    },
    checkCaseInfo() {
      if (
        this.getCaseInformation &&
        this.getCaseInformation.billing_info &&
        this.getCaseInformation.billing_info.insurance &&
        this.getCaseInformation.billing_info.insurance.secondary_insurance
      ) {
        this.secondaryInsuranceForm = this.setInsuranceDataFromBackendData(
          this.getCaseInformation.billing_info.insurance.secondary_insurance
        );
        this.payorData =
          this.getCaseInformation.billing_info.insurance.secondary_insurance
            .payor || {};
        this.showSecondaryInsuranceForm = false;
      }
    },

    clearNameSearch() {
      this.secondaryInsuranceForm = {
        relationship: "",
        plan_type: "",
        payer: "",
        payor: "",
        insurance_code: "",
        gender: "",
        date_of_birth: "",
        policy_plan: "",
        group_or_plan: "",
        group: "",
        patient_policy: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zip: "",
        first_name: "",
        middle_name: "",
        last_name: "",
      };
      this.clearPayorData = true;
      setTimeout(() => {
        this.show = true;
      }, 100);
    },
    fillInsurance(data) {
      if (data && data.name == "BCBS") {
        this.secondaryInsuranceForm.payor = data.name;

        this.secondaryInsuranceForm.insurance_carrier_code =
          this.stateCarrierMapping[this.facilityState] || "";
      } else if (data && data.name == "Medicaid") {
        this.secondaryInsuranceForm.payor = data.name;
        this.secondaryInsuranceForm.insurance_carrier_code =
          this.stateMedicaidCarrierMapping[this.facilityState] || "";
      } else if (data && data.name == "Aetna") {
        this.secondaryInsuranceForm.payor = data.name;
        this.secondaryInsuranceForm.insurance_carrier_code =
          this.stateAetnaMapping[this.facilityState] || "";
      } else if (data) {
        this.secondaryInsuranceForm.payor = data.name;
        if (data.payor_electronic_id) {
          this.secondaryInsuranceForm.insurance_carrier_code =
            data.payor_electronic_id;
        } else {
          this.secondaryInsuranceForm.insurance_carrier_code = "";
        }
      }
    },
    fillPrimaryDetails() {
      this.show = true;
      this.clearPayorData = false;
      if (this.secondaryInsuranceForm.relationship === "Self") {
        this.showPatientDob = false;
        let patientInformation =
          this.parentRefs && this.parentRefs.patientInformationForm
            ? this.parentRefs.patientInformationForm.formatPatientInfo()
            : this.patientData;
        this.secondaryInsuranceForm.first_name = patientInformation.first_name;
        this.secondaryInsuranceForm.middle_name =
          patientInformation.middle_name;
        this.secondaryInsuranceForm.last_name = patientInformation.last_name;
        this.secondaryInsuranceForm.gender = patientInformation.gender;

        this.secondaryInsuranceForm.date_of_birth =
          patientInformation.date_of_birth;

        this.secondaryInsuranceForm.city = patientInformation.city;
        this.secondaryInsuranceForm.state = patientInformation.state;
        this.secondaryInsuranceForm.zip = patientInformation.zip;
        this.secondaryInsuranceForm.address_line_1 =
          patientInformation.address_line_1;
        this.secondaryInsuranceForm.address_line_2 =
          patientInformation.address_line_2;
        setTimeout(() => {
          this.showPatientDob = true;
        }, 100);
      }
    },
  },
  watch: {
    refreshDob: {
      immediate: true,
      deep: true,
      handler: function () {
        this.showPatientDob = this.refreshDob;
      },
    },
    state: {
      immediate: true,
      deep: true,
      handler: function () {
        this.facilityState = this.state;
      },
    },
  },
};
</script>
